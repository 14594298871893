import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const buttonStyle = {
  width: "30px",
  background: "none",
  border: "0px",
};

const fadeImages = [
  {
    url: "./images/slides/01.jpg",
    caption: "First Slide",
  },
  {
    url: "./images/slides/02.jpg",
    caption: "Second Slide",
  },
  {
    url: "./images/slides/03.jpg",
    caption: "Third Slide",
  },
];
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="#fff"
        className="bi bi-caret-left-fill"
        viewBox="0 0 16 16"
      >
        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="#fff"
        className="bi bi-caret-right-fill"
        viewBox="0 0 16 16"
      >
        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
      </svg>
    </button>
  ),
};

export default function Slideshow() {
  return (
    <div className="slide-container">
      <Fade {...properties}>
        {fadeImages.map((fadeImage, index) => (
          <div key={index} style={{ "margin-top": "46px" }}>
            <img style={{ width: "100%" }} src={fadeImage.url} />
          </div>
        ))}
      </Fade>
    </div>
  );
}
