import React from 'react'

export default function Socialbar() {
  return (
    <div className="row container-fluid navbg w-100 m-0 pt-2">
        <div className='col-6 '>Social media icons</div>
        <div className="col-6 d-flex justify-content-end">
            <button className='btn btn-success text-white'>Book now</button>
        </div>
    </div>
  )
}
