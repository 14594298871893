import React from "react";

export default function () {
  return (
    <div className="container parallaxsec py-4">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src="./images/camera.png" width={90} alt="" />
            </div>
            <div className="col-12">
              <h2>Weddings</h2>
            </div>
            <div className="col-12">
              <p>
                Wedding photography captures the love, joy, and details of your
                special day, preserving memories through candid and authentic
                moments. It tells your unique story, creating timeless images
                you'll cherish forever.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src="./images/camera.png" width={90} alt="" />
            </div>
            <div className="col-12">
              <h2>Portraits</h2>
            </div>
            <div className="col-12">
              <p>
                Portrait photography captures the essence and personality of
                individuals, highlighting their unique characteristics. It
                creates timeless images that tell personal stories through
                expressions and emotions.
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src="./images/camera.png" width={90} alt="" />
            </div>
            <div className="col-12">
              <h2>Fashion</h2>
            </div>
            <div className="col-12">
              <p>
                Fashion photography showcases the artistry of clothing and
                accessories, capturing their unique style and elegance. It
                blends creativity and technique to create inspiring, visually
                stunning images.{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-3">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <img src="./images/camera.png" width={90} alt="" />
            </div>
            <div className="col-12">
              <h2>Editorial</h2>
            </div>
            <div className="col-12">
              <p>
                Photo editing enhances and refines images to bring out their
                best qualities, ensuring they reflect the intended mood and
                style. It involves adjusting colors, lighting, and details to
                create visually stunning and polished final results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
