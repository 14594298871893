import React, { useState, useEffect } from "react";
import axios from "axios";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // Lightbox styles

const Gallery = () => {
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    // Fetch categories on component mount
    axios
      .get(
        "https://bernard.imonline.ca/backend/processingengine.php?action=getCategories"
      )
      .then((response) => setCategories(response.data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const fetchImages = (categoryId) => {
    axios
      .get(
        `https://bernard.imonline.ca/backend/processingengine.php?action=getImages&category_id=${categoryId}`
      )
      .then((response) => setImages(response.data))
      .catch((error) => console.error("Error fetching images:", error));
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    fetchImages(categoryId);
  };

  const handleImageClick = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const photos = images.map((img) => ({
    src: img.url,
    width: img.width || 300, // Default width if not provided
    height: img.height || 200, // Default height if not provided
    alt: img.alt || "",
  }));

  return (
    <div className="container">
      <div className="my-3">
        {categories.map((category) => (
          <button
            key={category.id}
            className="btn btn-primary me-2"
            onClick={() => handleCategoryClick(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="row">
        {photos.map((photo, index) => (
          <div
            className="col-xs-12 col-sm-6 col-md-4 col-lg-2 mb-3"
            key={index}
          >
            <div
              className="position-relative"
              onClick={() => handleImageClick(index)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={photo.src}
                alt={photo.alt}
                className="img-fluid w-100"
                style={{ display: "block", width: "100%", height: "auto" }}
              />
            </div>
          </div>
        ))}
      </div>
      {lightboxOpen && (
        <Lightbox
          mainSrc={photos[currentImage]?.src}
          nextSrc={photos[(currentImage + 1) % photos.length]?.src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length]?.src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          // Ensure isOpen prop is not used here, as Lightbox uses conditional rendering
        />
      )}
    </div>
  );
};

export default Gallery;
