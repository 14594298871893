import "./App.css";
import Header from "./components/Header";
import Socialbar from "./components/Socialbar";
import Slideshow from "./components/Slideshow";
import Story from "./components/Story";
import Services from "./components/Services";
import CategoryManager from "./components/CategoryManager";
import Gallery from "./components/Gallery";

function App() {
  return (
    <>
      <main>
        <header>
          <Socialbar />
          <Header />
        </header>
        <Slideshow />
        <section className="container py-4">
          <Story />
        </section>
        <section className="parallax01">
          <Services />
        </section>
        <section>
          <Gallery />
        </section>
        <section>
          <CategoryManager />
        </section>
      </main>
    </>
  );
}

export default App;
