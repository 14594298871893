import React, { useState, useEffect } from "react";
import axios from "axios";

const API_URL = "https://bernard.imonline.ca/backend/processingengine.php";

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchImages();
    }
  }, [selectedCategory]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}?action=getCategories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        API_URL,
        { category_name: categoryName, action: "createCategory" },
        { headers: { "Content-Type": "application/json" } }
      );
      setCategoryName("");
      fetchCategories();
    } catch (error) {
      console.error("Error creating category:", error);
    }
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleImageUpload = async () => {
    if (selectedCategory === null) {
      alert("Please select a category first.");
      return;
    }

    const formData = new FormData();
    formData.append("action", "uploadImages");
    formData.append("category_id", selectedCategory);
    for (const file of files) {
      formData.append("files[]", file);
    }

    try {
      await axios.post(API_URL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.round((loaded * 100) / total);
          setUploadProgress((prev) => ({
            ...prev,
            [files[0].name]: percent,
          }));
        },
      });
      setFiles([]);
      setUploadProgress({});
      fetchImages(); // Fetch images after upload
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const fetchImages = async () => {
    if (selectedCategory === null) return;

    try {
      const response = await axios.get(API_URL, {
        params: { action: "getImages", category_id: selectedCategory },
      });
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleImageSelect = (id) => {
    setSelectedImages((prev) => {
      if (prev.includes(id)) {
        return prev.filter((imageId) => imageId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const handleDelete = async () => {
    if (selectedImages.length === 0) {
      alert("No images selected for deletion.");
      return;
    }

    try {
      await axios.post(API_URL, {
        action: "deleteImages",
        image_ids: selectedImages,
      });
      setSelectedImages([]);
      fetchImages(); // Refresh the image list
    } catch (error) {
      console.error("Error deleting images:", error);
    }
  };

  return (
    <div>
      {/* Category Creation Form */}
      <form onSubmit={handleCategorySubmit}>
        <input
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder="New category name"
        />
        <button type="submit">Create Category</button>
      </form>

      {/* Category Selection Dropdown */}
      <select
        onChange={(e) => setSelectedCategory(e.target.value)}
        value={selectedCategory}
      >
        <option value="">Select Category</option>
        {Array.isArray(categories) && categories.length > 0 ? (
          categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))
        ) : (
          <option value="" disabled>
            No categories available
          </option>
        )}
      </select>

      {/* File Upload */}
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={handleImageUpload}>Upload Images</button>

      {/* Upload Progress */}
      {files.length > 0 && (
        <div>
          <h3>Upload Progress</h3>
          <ul>
            {files.map((file) => (
              <li key={file.name}>
                {file.name}: {uploadProgress[file.name] || 0}%
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Image Gallery */}
      {selectedCategory && (
        <div>
          <h2>Image Gallery</h2>
          <div className="image-gallery">
            {images.map((image) => (
              <div key={image.id} className="image-thumbnail">
                <img src={image.url} alt="" width="100" height="100" />
                <div>
                  <input
                    type="checkbox"
                    checked={selectedImages.includes(image.id)}
                    onChange={() => handleImageSelect(image.id)}
                  />
                </div>
              </div>
            ))}
          </div>
          <button onClick={handleDelete} disabled={selectedImages.length === 0}>
            Delete Selected Images
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryManager;
