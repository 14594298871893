import React from 'react'

export default function Header() {
  return (
    <nav className="navbar navbar-expand-sm navbg navbar-dark">
  <div className="container-fluid">

      
      <a className="navbar-brand" href="#"><img src="./images/logo.png" width={180} alt="Bernard Konsol photography" /></a>
      
       <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon"></span>
    </button>
       
    
    <div className="collapse navbar-collapse justify-content-center" id="collapsibleNavbar">
      <ul className="navbar-nav ">
        <li className="nav-item">
          <a className="nav-link" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Portfolio</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Clients</a>
        </li>   
        <li className="nav-item">
          <a className="nav-link" href="#">Blog</a>
        </li>   
        <li className="nav-item">
          <a className="nav-link" href="#">Pricing</a>
        </li>   
        <li className="nav-item">
          <a className="nav-link" href="#">Contact</a>
        </li>    
      </ul>
    </div>
  </div>
</nav>
  )
}
