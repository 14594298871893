import React from "react";

export default function Story() {
  return (
    <div className="row">
      <div className="col-12">
        <h1>My story</h1>
      </div>
      <div className="col-12 col-sm-6">
        <h2>
          Bernard konsol is a Wedding & Portrait Photographer based in Montreal
        </h2>
      </div>
      <div className="col-12 col-sm-6">
        <p>
          Hello! I'm Bernard, a passionate and dedicated professional
          photographer with 15 years of experience capturing the beauty and
          essence of life. Whether it's the magic of a wedding day, the elegance
          of fashion, the charm of a home, or the detail of a product, my goal
          is to create stunning visuals that tell a story.
        </p>
        <p>
          Originally from the beautiful country of Lebanon, I've had the
          privilege of working all over the world, including the UAE, Jordan,
          Canada, Kuwait, Qatar, and many more. This global journey has enriched
          my perspective and honed my skills, allowing me to bring a unique and
          creative touch to every project.
        </p>
        <p>
          Photography is not just my profession; it's my joy. I believe in the
          power of images to capture memories and deliver happiness. This
          passion drives me to continually push the boundaries of creativity and
          excellence in every shot I take.
        </p>
        <p>
          When I'm not behind the camera, I'm enjoying life with my wonderful
          family—my spouse and our two amazing kids. They are my biggest
          inspiration and support, reminding me every day of the importance of
          capturing life's precious moments.
        </p>
        <p>
          Thank you for visiting my website. I look forward to the opportunity
          to work with you and create beautiful, lasting memories together.
        </p>
      </div>
    </div>
  );
}
